jQuery(function() {
  $('body.display_names .list-group-item').on('mouseenter', function() {
    $(this).children('.buttons').removeClass('hidden')
  })
  $('body.display_names .list-group-item').on('mouseleave', function() {
    $(this).children('.buttons').addClass('hidden')
  })

  let remainingAtStart = parseInt($('.remaining').html())
  if (remainingAtStart <= 0) {
    $('.celebrate-block').removeClass('hidden')
    $('.work-block').addClass('hidden')
  }

  $('body.display_names .list-group-item a').on('ajax:success', function(evt) {
    evt.stopPropagation()
    evt.preventDefault()
    decreaseRemaining()
    if ($(this).hasClass('accept')) {
      $(this).parents('.list-group-item').css({backgroundColor: '#28a745'})
    } else {
      $(this).parents('.list-group-item').css({backgroundColor: '#dc3545'})
    }
    $(this).parents('.list-group-item').fadeOut()
  })

  $('form.new_display_name').on('submit', function(evt) {
    // When someone presses enter on a form and hasn't blurred the field, the upcase never happens. This code prevents that behaviour.    
    if (document && document.activeElement) document.activeElement.blur()
  })

})

function decreaseRemaining() {
  let remaining = parseInt($('.remaining').html())
  remaining = remaining - 1
  $('.remaining').html(remaining)
  if (remaining <= 0) {
    $('.celebrate-block').removeClass('hidden')
    $('.work-block').addClass('hidden')
  }
}