jQuery(function() {

  $('form#new_donation').on('keydown', function(e) {
    if (e.which === 13 && e.target.id !== "donation_temp_postal_code" && e.target.id !== 'postal-code-lookup' && !e.target.classList.contains('select-postal-code') && !e.target.classList.contains('btn-next') && !e.target.classList.contains('btn-previous') && !e.target.classList.contains('btn-cancel')) {
      e.preventDefault();
      $(e.target).blur()
      $(e.target).focus()
    }
  })

  $('button#postal-code-lookup').on('click', function(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    $.ajax({
      url: '/canada_postal_addresses',
      data: {postal_code: $('#donation_temp_postal_code').val()},
      dataType: 'json',
      method: 'GET'
    }).done(function(data) {
        // clear out any existing data in the table
      $('table.postal-code-search-results tbody').empty()
      for (i = 0; i < data.length; i++) {
        let first3 = data[i].postal_code.substring(0,3)
        let province = data[i].province

        $('table.postal-code-search-results tbody').append("<tr><td>"+data[i].postal_code+"</td><td>"+data[i].city+"</td><td>"+province+"</td><td>"+data[i].street_name+"</td><td>"+data[i].street_type+"</td><td><a data-postal-code='"+data[i].postal_code+"' data-city='"+data[i].city+"' data-province='"+province+"' data-street-name='"+data[i].street_name+"' data-street-type='"+data[i].street_type+"' href='#' class='select-postal-code'>Select</a></tr>")
      }
      $('a.select-postal-code').on('click', function(evt) {
        evt.preventDefault()
        evt.stopPropagation()

        $('table.postal-code-search-results tr').removeClass('table-warning')
        $(this).parents('tr').addClass('table-warning')

        $('input#donation_postal_code')
          .val(this.dataset["postalCode"])
          .change()
          .blur()
          .addClass('is-valid')

        $('input#donation_city')
          .val(this.dataset["city"])
          .change()
          .blur()
          .addClass('is-valid')

        $('select#donation_province')
          .val(this.dataset["province"])
          .change()
          .blur()
          .addClass('is-valid')

        $('input#donation_street_name')
          .val(this.dataset["streetName"] + ' ' + this.dataset["streetType"])
          .change()
          .blur()
        
        validatePage(this)
      })
      $('.postal-code-search-results').removeClass('hidden')
      $('.postal-code-search-instructions').removeClass('hidden')  
    })
  })

  $('#donation_submission_confirmation').on('change', function () {
    if ($(this)[0].checked) {
      $('#donation_submission_confirmation_check').val('filled').change().blur()
      $('.btn.btn-done').removeClass('disabled')
      $('input#donation_validate_only').val('false')
      $('form#new_donation').attr('data-remote', false)
    } else {
      $('#donation_submission_confirmation_check').val('').change().blur()
      $('.btn.btn-done').addClass('disabled')
      $('input#donation_validate_only').val('true')
      $('form#new_donation').attr('data-remote', true)
    }
  })

  $('form.edit_donation, form.new_donation').on('submit', function(evt) {
    // When someone presses enter on a form and hasn't blurred the field, the upcase never happens. This code prevents that behaviour.    
    if (document && document.activeElement) document.activeElement.blur()
  })

  $('body.donations-credit_card form#credit_card_donation').on('submit', function(evt) {
    evt.preventDefault();
    var form = $(this);
    var url = form.attr('action');
    $('.donation-spinner').removeClass('hidden')
    $('.donation-button').prop('disabled', true)
    $('input#card_number').inputmask('remove')
    $('input#card_exp_date').inputmask('remove')
    $('input#card_number').val($('input#card_number').val().replace(/ /g, ''))
    $('input#card_exp_date').val($('input#card_exp_date').val().replace(/\//g, '/20'))
    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize()
    }).done(function(data) {
      $.ajax({
        type: "PUT",
        url: window.location,
        data: {"response": data}
      })
      var response = JSON.parse(data)
      $('form#credit_card_donation').remove()
      $('.success').removeClass('hidden')
      $('.donation-spinner').addClass('hidden')
      $('.donation-button').prop('disabled', false)
    }).fail(function(data) {
      $.ajax({
        type: "PUT",
        url: window.location,
        data: {"response": data.responseText}
      })
      var response = JSON.parse(data.responseText)
      let errors = response.donationResponse.errors
      $('.error').removeClass('hidden')
      $('.error-note').text(errors.message)
      if (errors.declineUserMessage !== undefined)
        $('.error-note').text(errors.declineUserMessage)
      if (errors.fieldError !== undefined) {
        for (let i = 0; i < errors.fieldError.length; i++) {
          $('.error .error-details').append("<li>"+errors.fieldError[i]+"</li>")
        }
      }
      $('.donation-spinner').addClass('hidden')
      $('.donation-button').prop('disabled', false)
      $('input#card_exp_date').val($('input#card_exp_date').val().replace(/\/20/g, '/'))
      $('#card_number').inputmask('9999 9999 9999 9999', {placeholder: ' '})
      $('#card_exp_date').inputmask('99/99', {placeholder: ' '})
    });
  })

  $('body.donations-wizard form#new_donation').on('ajax:success', function(evt) {
    [data, status, xhr] = evt.detail

    $('.validation-errors').empty() // clear any existing validation errors

    document.querySelector('.review-section').scrollIntoView({
      behavior: 'smooth'
    });

    // holding onto this code temporarily as we figure out how to do credit cards. I suspect the controller will do some redirecting, though.
    // if ($('select#donation_donation_method').val() === "Visa or MasterCard") {
    //   window.location="/donations/"+data.id+"/credit_card"
    //   return
    // }
  }).on('ajax:error', function(evt) {
    [data, status, xhr] = evt.detail

    $('.validation-errors').empty() // clear any existing validation errors

    document.querySelector('.review-section').scrollIntoView({
      behavior: 'smooth'
    });
    
    $('.btn.btn-done').addClass('disabled')

    if (status === "Internal Server Error") {
      $('.server-response').html("<p>There has been a catastrophic server error. Show this to a Team Captain, and raise the alarm right away.</p><pre>"+data+"</pre>")
      return
    }

    // This is most likely a 400 error, so let's show the bad info.
    $('.server-response').html("<p>There are validation errors. See below.</p>")
    for (let [key, values] of Object.entries(data)) {
      $('.donation_'+key+'-errors').html(values.join('; '))
    }
  })

  $('input#donation_amount').on('change', function() {
    let amount = $(this).val()
    if ($('.user-role').html() === "agent" && amount > 200) {
      if (!confirm("The amount entered is over $200. Is this correct?")) {
        $(this).val('')
      }
    }
    if (amount < 0) {
      alert("Amounts less than $0 are not valid.")
      $(this).val('')
    }
  })

  $('input#donation_house_no, input#donation_street_name').on('change', function() {
    $('input#donation_address')
      .val(
        $('input#donation_house_no')
          .val()
          .toUpperCase() + ' ' + $('input#donation_street_name')
          .val()
          .toUpperCase()
        )
      .change()
      .blur()
  })

  $('input#donation_first, input#donation_last').on('change', function() {
    $('input#donation_prime_display_1')
      .val(
        $('input#donation_first')
          .val()
          .toUpperCase() + ' ' + $('input#donation_last')
          .val()
          .toUpperCase()
        )
      .change()
      .blur()
  })

  $('input#donation_city, select#donation_province').on('change', function() {
    let donation_province = $('select#donation_province')
      .val()
      .toUpperCase()
    $('input#donation_prime_display_2')
      .val(
        $('input#donation_city')
        .val()
        .toUpperCase() + ', ' + donation_province
      )
      .change()
      .blur()

    $('.prime_display_2').html($('input#donation_prime_display_2').val())
  })

  $('input#donation_prime_display_1').on('change', function() {
    displayNameChanged('prime', '1')
  })

  $('input#donation_prime_display_2').on('change', function() {
    displayNameChanged('prime', '2')
  })

  $('input#donation_chyron').on('change', function() {
    let val = $(this).val()
    if (val === "true") {
      $('.displayname-confirmation').removeClass('hidden')
    } else {
      $('.displayname-confirmation').addClass('hidden')

      // clear out the contents of the fields
      $('input#donation_prime_display_1').val('').change()
      $('input#donation_prime_display_2').val('').change()
    }
    validatePage(this)
  })

  $('select#donation_donation_type').on('change', function() {
    let val = $(this).val()
    if (val === "Monthly") {
      $('.donation-duration-control').removeClass('hidden')
    } else {
      $('.donation-duration-control').addClass('hidden')
    }
  })

  $('input#donation_fundraised').on('change', function() {
    let val = $(this).prop('checked')
    if (val) {
      $('.fundraised-confirmation').removeClass('hidden')
    } else {
      $('.fundraised-confirmation').addClass('hidden')
    }
  })

  $('button.btn-review').on('click', function() {
    $('.review-section').removeClass('hidden')
    $(this).text("Update review")
  })

  // on the non-wizard pages
  $('form.edit_donation input#donation_chyron, form.new_donation input#donation_chyron').on('change', function() {
    let val = $(this).prop('checked')
    if (val) {
      $('input#donation_prime_display_1').prop('disabled', false)
      $('input#donation_prime_display_2').prop('disabled', false)
    } else {
      $('input#donation_prime_display_1').val('').removeClass('required').removeClass('is-valid').removeClass("is-invalid").change()
      $('input#donation_prime_display_2').val('').removeClass('required').removeClass('is-valid').removeClass("is-invalid").change()
      $('input#donation_prime_display_1').prop('disabled', true)
      $('input#donation_prime_display_2').prop('disabled', true)
    }
  })

  // Change all input.uppercase to actually be uppercase, rather than simply appearing uppercase due to the text-transform: uppercase CSS instruction
  $('input.uppercase').on('blur', function() {
    $(this).val($(this).val().toUpperCase())
  })

  $('input, select').on('blur', function() {
    // run validations
    let val = $(this).val()
    let valid = false

    if ($(this).hasClass('required')) {
      if (val === "") {
        valid = false
      } else {
        valid = true
      }
    } else {
      valid = null
    }

    // postal code field happens to always be required, so in the case of an empty val(), this will be set to false
    if (/postal_code/.exec($(this).attr('id'))) {
      let postal_code_re = /^[A-Z]\d[A-Z] ?\d[A-Z]\d$/
      let pc_match = postal_code_re.exec(val)
      if (pc_match === null) {
        valid = false
      } else {
        valid = true
      }
    }

    // phone number is always required
    if (/phone/.exec($(this).attr('id'))) {
      let phone_re = /^(\d\d\d)-?(\d\d\d)-?(\d\d\d\d)$/
      let phone_match = phone_re.exec(val)
      if (phone_match === null) {
        valid = false
      } else {
        valid = true
        $(this).val(phone_match[1] + '-' + phone_match[2] + '-' + phone_match[3])
      }
    }

    // non-required fields are not subject to verification
    if (valid !== null) {
      if (valid) {
        $(this).addClass('is-valid')
        $(this).removeClass('is-invalid')
      } else {
        $(this).addClass('is-invalid')
        $(this).removeClass('is-valid')
      }
    }

    validatePage(this)

    // update the data on the review page
    let id = this.id
    let value = this.value
    $('.'+id+'-confirm').html(value)
  })

  $('input#donation_temp_postal_code').on('change', function() {
    $('input#donation_postal_code').val($('input#donation_temp_postal_code').val())
    $('input#donation_postal_code').addClass('is-valid')
    $('input#postal_code').val()
  })

  $('.donation-state-control').on('change', function() {
    if ($(this).val() === "Paid") {
      $('.paid-detail').show()
    } else {
      $('.paid-detail').hide()
    }
  })

  $('#donation_receipt_requested').on('change', function() {
    if ($(this)[0].checked) {
      $('#donation_receipt_date').attr('disabled', false)
    } else {
      $('#donation_receipt_date').attr('disabled', true)
    }
  })

  // input masks
  $('#donation_temp_postal_code').inputmask('A9A 9A9', {placeholder: ' '})
  $('#donation_postal_code').inputmask('A9A 9A9', {placeholder: ' '})
  $('#donation_phone').inputmask('999-999-9999', {placeholder: ' '})
  $('#card_number').inputmask('9999 9999 9999 9999', {placeholder: ' '})
  $('#card_exp_date').inputmask('99/99', {placeholder: ' '})
  $('#card_cvv').inputmask('999', {placeholder: ' '})

  // Blackbaud Checkout event handlers
  $(document).on('checkoutComplete', function(event) {
    console.log('Token: ', event.detail.transactionToken);
    $('.credit_card_options').hide();
    $('.please_wait').show();
  });

  $(document).on('checkoutCancel', function(event) {
    console.log(event);
    console.log('Checkout form closed by user');
  });

  $(document).on('checkoutError', function(event) {
    console.log(event);
    console.log('Blackbaud Checkout returned error');
  });

})

function validatePage(elem) {
  let page = window.location.hash
  if (page === "") {
    page = '#find-address'
  }

  // check for page-level validation concerns (beyond a single required field)
  if (page === "#address-info" && elem.id !== 'donation_phone') {
    let house_no = $('#donation_house_no')
    let street_name = $('#donation_street_name')
    let po_box = $('#donation_po_box')

    if (
      (house_no.val() === "" && street_name.val() === "" && po_box.val() === "") ||
      ((house_no.val() === "" || street_name.val() === "") && po_box.val() === "")
    ) {
      house_no.addClass('required').addClass('is-invalid')
      street_name.addClass('required').addClass('is-invalid')
      po_box.addClass('required').addClass('is-invalid')
    } else {
      house_no.removeClass('required').removeClass('is-invalid').addClass('is-valid')
      street_name.removeClass('required').removeClass('is-invalid').addClass('is-valid')
      po_box.removeClass('required').removeClass('is-invalid').addClass('is-valid')
    }
  }

  // check for required fields and make sure they're set
  let required_fields = $(elem).parents('.tab-pane').find('select.required, input.required')
  let invalid_fields = $(elem).parents('.tab-pane').find('select.required.is-invalid, input.required.is-invalid')
  let valid_fields = $(elem).parents('.tab-pane').find('select.required.is-valid, input.required.is-valid')
  let is_valid = false
  if (invalid_fields.length == 0 && required_fields.length === valid_fields.length) {
    is_valid = true
  }
  // console.log(page + " is_valid: "+is_valid+" -- required_fields: "+required_fields.length + " invalid_fields: "+invalid_fields.length + " valid_fields: "+valid_fields.length)

  if (is_valid) {
    $(elem).parents('.tab-pane').find('.btn-next,.btn-done').removeClass('disabled')
    $(elem).parents('.container').find('a.nav-link[href="'+page+'"]').addClass('is-valid')
    $(elem).parents('.container').find('a.nav-link[href="'+page+'"]').removeClass('is-invalid')
    $(elem).parents('.tab-pane').find('.btn-done').prop('disabled', false)
  } else {
    $(elem).parents('.tab-pane').find('.btn-next,.btn-done').addClass('disabled')
    $(elem).parents('.container').find('a.nav-link[href="'+page+'"]').addClass('is-invalid')
    $(elem).parents('.container').find('a.nav-link[href="'+page+'"]').removeClass('is-valid')
    $(elem).parents('.tab-pane').find('.btn-done').prop('disabled', true)
  }
}

function displayNamesCorrect(_this, which) {
  let val = $(_this).val()
  if (val === "false") {
    $('.'+which+'-display').addClass('hidden')
    $('.override-'+which+'-display').removeClass('hidden')
    $(_this).removeClass('is-valid')
    $(_this).addClass('is-invalid')
  } else {
    $('.'+which+'-display').removeClass('hidden')
    $('.override-'+which+'-display').addClass('hidden')
  }
}

function updateDisplayNames(which) {
  $('input#donation_'+which+'_display_1').val($('input#donation_'+which+'_display_1').val().toUpperCase())
  $('input#donation_'+which+'_display_2').val($('input#donation_'+which+'_display_2').val().toUpperCase())
  $('.'+which+'_display_1').html($('input#donation_'+which+'_display_1').val())
  $('.'+which+'_display_2').html($('input#donation_'+which+'_display_2').val())
  $('.'+which+'-display').removeClass('hidden')
  $('.override-'+which+'-display').addClass('hidden')
  $('select#donation_'+which+'_display_names_correct').val('').removeClass('is-valid').addClass('is-invalid').change()
}

function displayNameChanged(which, line) {
  $('input#donation_'+which+'_display_'+line)
    .val(
      $('input#donation_'+which+'_display_'+line)
        .val()
        .substring(0,32)
      )
    // .change() DO NOT FIRE CHANGE AGAIN!
    .blur() // updates the -confirm element on the review page

  $('.'+which+'_display_'+line).html($('input#donation_'+which+'_display_'+line).val())
}
